import ChopMaster from "./components/Chopmaster.vue";
import Winner from "./components/Winner.vue";
import FreeBet from "./pages/auth/free-bet/FreeBet.vue";
import BetSlip from "./pages/bet-slip/BetSlip.vue";
import ChangePin from "./pages/change-pin/ChangePin.vue";
import ChopAgent from "./pages/deposit/chop-agent/ChopAgent.vue";
import Status from "./pages/deposit/status/Status.vue";
import ForgotPin from "./pages/forgot-pin/ForgotPin.vue";
import Games from "./pages/games/Games.vue";
import Gift from "./pages/gifts/Gift.vue";
import GiftDetail from "./pages/gifts/GiftDetail.vue";
import Language from "./pages/language/Language.vue";
import MyReferral from "./pages/my-referral/MyReferral.vue";
import Results from "./pages/results/results.vue";
import Statistics from "./pages/statistics/statistics.vue";
import ChopAgentWithdraw from "./pages/withdraw/chop-agent/ChopAgentWithdraw.vue";
import { metaData } from "./seo";

const MyAccount = () => import("./pages/my-account/MyAccount.vue");
const Deposit = () => import("./pages/deposit/Deposit.vue");
const MoovDeposit = () => import("./pages/deposit/moov/MoovDeposit.vue");
const MtnDeposit = () => import("./pages/deposit/mtn/MtnDeposit.vue");
const OrangeDeposit = () => import("./pages/deposit/orange/OrangeDeposit.vue");
const WaveDeposit = () => import("./pages/deposit/wave/WaveDeposit.vue");
const MoovWithdraw = () => import("./pages/withdraw/moov/MoovWithdraw.vue");
const MtnWithdraw = () => import("./pages/withdraw/mtn/MtnWithdraw.vue");
const OrangeWithdraw = () =>
  import("./pages/withdraw/orange/OrangeWithdraw.vue");
const WaveWithdraw = () => import("./pages/withdraw/wave/WaveWithdraw.vue");
const Withdraw = () => import("./pages/withdraw/Withdraw.vue");
const NotFound = () => import("./pages/404/NotFound.vue");
const Sport = () => import("./components/Sport.vue");
const MobileApp = () => import("./components/MobileApp");
const Login = () => import("./pages/auth/login/Login.vue");
const ProfileUpdate = () => import("./pages/profile/edit/ProfileUpdate.vue");
const CustomerSupport = () => import("./components/CustomerSupport");
const Join = () => import("./pages/auth/join/Join.vue");
const ForgotPassword = () =>
  import("./pages/auth/forgot-password/ForgotPassword.vue");
const ResetPassword = () =>
  import("./pages/auth/reset-password/ResetPassword.vue");
const ChangePassword = () =>
  import("./pages/profile/change-password/ChangePassword.vue");
const ResetPasswordNew = () =>
  import("./pages/auth/reset-password-new/ResetPassword.vue");
const AccountActivation = () =>
  import("./pages/auth/activate-account/AccountActivation.vue");
const Reset = () => import("./components/Reset");
const SpecialOffer = () => import("./components/specialOffer/Home.vue");
const Terms = () => import("./pages/help/terms/Terms.vue");
const Privacy = () => import("./pages/help/privacy-policy/Privacy.vue");
const Search = () => import("./pages/search/Search.vue");
const Live = () => import("./pages/live/Live.vue");
const Rules = () => import("./pages/help/rules/Rules.vue");
const Faq = () => import("./pages/help/faq/Faq.vue");
const Freebet = () => import("@/components/Freebet.vue");
const Responsible = () =>
  import("./pages/help/betting-responsible/Responsible.vue");
const Leaderboard = () => import("./pages/leaderboard/Leaderboard.vue");
const AccountVerification = () =>
  import("./pages/profile/acccount-verification/AccountVerification.vue");
const UploadIdentity = () =>
  import("./pages/profile/acccount-verification/upload/UploadIdentity.vue");
const AccountVerificationSuccess = () =>
  import(
    "./pages/profile/acccount-verification/upload-success/AccountVerificationSuccess.vue"
  );
const Jackpot = () => import("./pages/jackpot/Jackpot.vue");
const PlayJackpot = () => import("./pages/play-jackpot/PlayJackpot.vue");
const MyBets = () => import("./pages/my-bets/MyBets.vue");
const Transactions = () => import("./pages/transactions/Transactions.vue");
const Match = () => import("./pages/match/Match.vue");
const Inbox = () => import("./pages/inbox/Inbox.vue");

export const routes = [
  {
    path: "/",
    name: "sports",
    component: Sport,
    meta: {
      ...metaData,
    },
  },
  {
    path: "/play-dev",
    name: "PlayJackpot",
    component: PlayJackpot,
    meta: {
      ...metaData,
    },
  },
  {
    path: "/play-jackpot/:id",
    name: "JackpotPlay",
    component: PlayJackpot,
    meta: {
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },

  {
    path: "/search",
    name: "search",
    component: Search,
    meta: {
      ...metaData,
    },
  },
  {
    path: "/live",
    name: "live",
    component: Live,
    meta: {
      ...metaData,
    },
  },
  {
    path: "/freebet",
    name: "freebet",
    component: Freebet,
    meta: {
      ...metaData,
    },
  },
  {
    path: "/match/:status/:game_id/:name",
    name: "match",
    component: Match,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      ...metaData,
    },
  },
  {
    path: "/join",
    name: "join",
    component: Join,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChangePassword,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: false,
      ...metaData,
    },
  },
  {
    path: "/change-pin",
    name: "ChangePin",
    component: ChangePin,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: false,
      ...metaData,
    },
  },
  {
    path: "/forgot-pin",
    name: "forgot-pin",
    component: ForgotPin,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: false,
      ...metaData,
    },
  },
  {
    path: "/reset-password/new",
    name: "reset-password",
    component: ResetPasswordNew,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/activate-account",
    name: "Account-Activation",
    component: AccountActivation,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/free-bet",
    name: "free-bet",
    component: FreeBet,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/profile-update",
    name: "profile-update",
    component: ProfileUpdate,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      showFooter: true,
      ...metaData,
    },
  },
  {
    path: "/account-verification",
    name: "account-verification",
    component: AccountVerification,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: false,
      ...metaData,
    },
  },
  {
    path: "/upload",
    name: "upload",
    component: UploadIdentity,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      ...metaData,
    },
  },
  {
    path: "/verification-success",
    name: "account-verification-success",
    component: AccountVerificationSuccess,
    meta: {
      hideMenu: true,
      hideGamesMenu: true,
      ...metaData,
    },
  },
  {
    path: "/customer-support",
    name: "customer-support",
    component: CustomerSupport,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      ...metaData,
    },
  },
  {
    path: "/inbox",
    name: "inbox",
    component: Inbox,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      ...metaData,
    },
  },
  {
    path: "/language",
    name: "language",
    component: Language,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      ...metaData,
    },
  },

  {
    path: "/leaderboard",
    name: "leaderboard",
    component: Leaderboard,
    meta: {
      hideMenu: false,
      ...metaData,
    },
  },
  {
    path: "/results",
    name: "results",
    component: Results,
    meta: {
      hideMenu: false,
      ...metaData,
    },
  },
  {
    path: "/Statistics",
    name: "Statistics",
    component: Statistics,
    meta: {
      hideMenu: false,
      ...metaData,
    },
  },
  {
    path: "/deposit",
    name: "deposit",
    component: Deposit,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/deposit/status/:status",
    name: "deposit-status",
    component: Status,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: Withdraw,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/deposit/wave",
    name: "wave-deposit",
    component: WaveDeposit,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/deposit/chop-agent",
    name: "chop-agent",
    component: ChopAgent,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/withdraw/chop-agent",
    name: "chop-agent-withdraw",
    component: ChopAgentWithdraw,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/deposit/orange",
    name: "orange-deposit",
    component: OrangeDeposit,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/deposit/mtn",
    name: "mtn-deposit",
    component: MtnDeposit,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/deposit/moov",
    name: "moov-deposit",
    component: MoovDeposit,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },

  {
    path: "/withdraw/wave",
    name: "wave-withdraw",
    component: WaveWithdraw,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/withdraw/orange",
    name: "orange-withdraw",
    component: OrangeWithdraw,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/withdraw/mtn",
    name: "mtn-withdraw",
    component: MtnWithdraw,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/withdraw/moov",
    name: "moov-withdraw",
    component: MoovWithdraw,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
    },
  },
  {
    path: "/chopmaster",
    name: "chopmaster",
    component: ChopMaster,
    meta: {
      ...metaData,
      ogUrl: "https://chopLife.com",
    },
  },
  {
    path: "/winner",
    name: "winner",
    component: Winner,
    meta: {
      ...metaData,
      ogUrl: "https://chopLife.com",
    },
  },
  {
    path: "/bet-slip",
    name: "bet-slip",
    component: BetSlip,
    meta: {
      hideGamesMenu: true,
      hideBottomNavigation: true,
      showFooter: false,
      ...metaData,
      ogUrl: "https://chopbet.ci",
    },
  },
  {
    path: "/bet-slip/:id",
    name: "bet-slip",
    component: BetSlip,
    meta: {
      hideGamesMenu: true,
      hideBottomNavigation: true,
      showFooter: false,
      ...metaData,
      ogUrl: "https://chopbet.ci",
    },
  },
  {
    path: "/transactions",
    name: "transactions",
    component: Transactions,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      ...metaData,
      ogUrl: "https://chopbet.ci",
    },
  },
  {
    path: "/special-offer",
    name: "SpecialOffer",
    component: SpecialOffer,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
      ogUrl: "https://chopbet.ci",
    },
  },
  {
    path: "/my-account",
    name: "my-account",
    component: MyAccount,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: false,
      showFooter: true,
      ...metaData,
      ogUrl: "https://chopbet.ci/my-account",
    },
  },
  {
    path: "/referral",
    name: "Referral",
    component: MyReferral,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      hideBottomNavigation: false,
      showFooter: true,
      ...metaData,
      ogUrl: "https://chopbet.ci/my-account",
    },
  },
  {
    path: "/my-bets",
    name: "my-bets",
    component: MyBets,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      ...metaData,
      ogUrl: "https://chopbet.ci/my-bets",
    },
  },
  {
    path: "/my-bets/:id",
    name: "my-bets",
    component: MyBets,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      ...metaData,
      ogUrl: "https://chopbet.ci/my-bets",
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      showFooter: true,
      ...metaData,
      ogUrl: "https://chopbet.ci/terms",
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      showFooter: true,
      ...metaData,
      ogUrl: "https://chopbet.ci/privacy",
    },
  },
  {
    path: "/rules",
    name: "rules",
    component: Rules,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      showFooter: true,
      ...metaData,
      ogUrl: "https://chopbet.ci/rules",
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: Faq,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      showFooter: true,
      ...metaData,
      ogUrl: "https://chopbet.ci/faq",
    },
  },
  {
    path: "/gifts",
    name: "gifts",
    component: Gift,
    meta: {
      ...metaData,
      ogUrl: "https://chopbet.ci/gifts",
    },
  },
  {
    path: "/gifts/:id",
    name: "gifts",
    component: GiftDetail,
    meta: {
      ...metaData,
      ogUrl: "https://chopbet.ci/gifts/:id",
    },
  },
  {
    path: "/mobile-app",
    name: "mobile-app",
    component: MobileApp,
    meta: {
      ...metaData,
    },
  },
  {
    path: "/responsible-gaming",
    name: "responsible",
    component: Responsible,
    meta: {
      hideMenu: false,
      hideGamesMenu: true,
      showFooter: true,
      ...metaData,
      ogUrl: "https://chopbet.ci/responsible-gaming",
    },
  },
  {
    path: "/reset/:msisdn/:code",
    name: "reset",
    component: Reset,
    meta: {
      ...metaData,
      ogUrl: "https://chopbet.ci/reset/:msisdn/:code",
    },
  },
  {
    path: "/jackpot",
    name: "jackpot",
    component: Jackpot,
    meta: {
      ...metaData,
      ogUrl: "https://chopbet.ci/jackpot-games",
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
    meta: {
      hideGamesMenu: true,
      hideBottomNavigation: true,
      ...metaData,
      ogUrl: "https://chopbet.ci/404",
    },
  },
  {
    path: "/games",
    name: "games",
    component: Games,
    meta: {
      ...metaData,
      ogUrl: "https://chopbet.ci/games",
    },
  },
  {
    path: "/games/:category/:game_name",
    name: "games",
    component: Games,
    meta: {
      ...metaData,
      ogUrl: "https://chopbet.ci/games",
    },
  },
];
