import { render, staticRenderFns } from "./DepositComponent.vue?vue&type=template&id=006f4a62&scoped=true"
import script from "./DepositComponent.vue?vue&type=script&lang=js"
export * from "./DepositComponent.vue?vue&type=script&lang=js"
import style0 from "./DepositComponent.vue?vue&type=style&index=0&id=006f4a62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "006f4a62",
  null
  
)

export default component.exports