<template>
  <div :class="inputClass || 'phone-number'">
    <div class="input-group" v-if="type === 'password'">
      <input
        :type="inputType"
        class="form-control"
        id="inputPass"
        style="
          border-radius: 5px;
          background-color: var(--background-color);
          color: var(--text-color) !important;
        "
        :name="password"
        :placeholder="placeholder || $t('password')"
        :aria-label="$t('password')"
        :value="value"
        :maxlength="maxLength"
        autocomplete="off"
        @input="updateValue($event)"
      />
      <div
        class="input-group-prepend"
        style="
          border-radius: 5px;
          background-color: var(--background-color);
          color: var(--text-color);
        "
      >
        <span
          style="background-color: var(--background-color)"
          class="btn btn-sm"
          type="button"
          ><img @click="toggleVisibility" style="width: 16px" :src="btnText"
        /></span>
      </div>
    </div>
    <div v-else>
      <div class="input_wrapper phone_number">
        <div class="country">
          <div>
            <CountryFlag />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M13.28 5.9668L8.9333 10.3135C8.41997 10.8268 7.57997 10.8268 7.06664 10.3135L2.71997 5.9668"
                stroke="#BAB7B7"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span>+225</span>
        </div>
        <label for="">{{ $t("phoneNumber") }}</label>
        <input
          :id="id"
          :name="name"
          type="number"
          class="form-control"
          :value="value"
          autocomplete="off"
          :aria-label="label"
          @input="onInput($event)"
          :max="9999999999"
          aria-autocomplete="none"
          :placeholder="placeholder"
          inputmode="numeric"
          :readonly="readonly"
        />
      </div>
    </div>
  </div>
</template>

<script>
import eyeIcon from "../../../assets/images/icons/eye.svg";
import eyeSlashIcon from "../../../assets/images/icons/eye-slash.svg";
import CountryFlag from "../icons/CountryFlag.vue";

export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    inputId: {
      type: String,
      default: () => `input-${Math.random().toString(36).slice(2, 11)}`,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
      default: 999,
    },
  },
  components: {
    CountryFlag,
  },
  data() {
    return {
      inputType: this.type,
      btnText: "/img/icons/eye.svg",
      btnText2: "/img/other/eye.svg",
      btnText3: "/img/other/eye.svg",
    };
  },
  computed: {
    inputPrependStyle() {
      return {
        backgroundColor: "white",
        color: "black",
      };
    },
  },
  methods: {
    toggleVisibility() {
      this.inputType = this.inputType === "password" ? "text" : "password";
      this.btnText = this.inputType === "password" ? eyeIcon : eyeSlashIcon;
    },
    updateValue(event) {
      this.$emit("input", event.target.value);
    },
    // onInput(event) {
    //   const inputValue = event.target.value;
    //   if (inputValue.length <= 10) {
    //     this.$emit("input", inputValue);
    //   } else {
    //     event.target.value = inputValue.slice(0, 10);
    //   }
    // },
    onInput(event) {
      let inputValue = event.target.value.replace(/\D/g, "");

      if (inputValue.length > 10) {
        inputValue = inputValue.slice(0, 10);
      }
      event.target.value = inputValue;
      this.$emit("input", inputValue);
    },
    showPassword: function () {
      if (document.getElementById("inputPass").type == "password") {
        document.getElementById("inputPass").type = "text";
        document.getElementById("inputPass").style.color = "white";
      } else {
        document.getElementById("inputPass").type = "password";
        document.getElementById("inputPass").style.color =
          "rgba(255,255,255,.75)";
      }

      if (this.inputType === "password") {
        this.inputType = "text";
        this.btnText = "/img/icons/eye-slash.svg";
      } else {
        this.inputType = "password";
        this.btnText = "/img/icons/eye.svg";
      }
    },
  },
};
</script>

<style scoped>
.phone_number {
  position: relative;
  display: flex;
  height: 52px;
  justify-content: center;
  align-items: flex-end;
  gap: 2px;
  border-radius: 4px;
  margin-bottom: 16px;
  outline: none !important;
}
.phone_number label {
  position: absolute;
  top: 6px;
  left: 70px;
  color: #545151;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.phone_number input {
  font-size: 14px;
  padding-top: 4px;
  padding: 10px 0;
  padding-left: 100px;
  padding-top: 30px !important;
}
.country {
  gap: 8px;
  position: absolute;
  top: 17px;
  left: 20px;
  width: 80px;
  display: flex;
  align-items: center;
}

.country span {
  margin-bottom: -22px;
  color: #8c8787;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.14px;
}
.country div:nth-child(1) {
  display: flex;
  align-items: center;
}

.input-group {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d1cfcf;
  background-color: var(--background-color);
  color: var(--text-color) !important;
  height: 52px;
  outline: none;
}

.input-group input::placeholder {
  font-size: 14px;
  color: #8c8787;
  background-color: var(--background-color);
  font-weight: 300;
}

.form-control:focus {
  background-color: transparent !important;
  box-shadow: none;
}
.form-control::placeholder {
  font-weight: 200 !important;
}
.form-control:disabled,
.form-control[readonly] {
  background: transparent;
}

.btn-sm {
  padding-top: 13px;
}
</style>
